<template>
  <b-card v-if="allEmployee" no-body class="card-company-table">
    <b-table
      ref="refUserListTable"
      class="position-relative"
      :items="allEmployee"
      responsive
      :fields="tableColumnsHome"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >
      <!-- Column: User -->
      <template #cell(user)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="constructUrlImage('')"
              :variant="`light-${mappingStatusColor(data.item.employee.role)}`"
              :to="{
                name: 'view-employee',
                params: { id: data.item.employee._id },
              }"
            />
          </template>
          <b-link
            :to="{
              name: 'view-employee',
              params: { id: data.item.employee._id },
            }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.employee.firstName }}
            {{ data.item.employee.lastName }}
          </b-link>
          <small class="text-muted">@{{ data.item.employee.nationalID }}</small>
        </b-media>
      </template>

      <!-- Column: NATIONAL ID -->
      <template #cell(nationalID)="data">
        {{ data.item.employee.nationalID }}
      </template>

      <!-- Column: Role -->
      <!-- <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.department)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.department)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.department
            }}</span>
          </div>
        </template> -->

      <!-- Column: Status -->
      <template #cell(status)="data">
        <span
          style="margin-left: 10px"
          class="bullet bullet-sm"
          :class="`bullet-${mappingStatusColor(data.item.employee.status)}`"
        />
        <!-- <b-badge
          pill
          :variant="`light-${resolveUserStatusVariant('active')}`"
          class="text-capitalize"
        >
          {{ data.item.status || "active" }}
        </b-badge> -->
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BImg,
  BLink,
  BBadge,
  BMedia,
} from "bootstrap-vue";
import { constructUrlImage } from "@/libs/helper";
import useUsersList from "../employee/useUsersList";
import { avatarText } from "@core/utils/filter";
import { mappingStatusColor } from "@/libs/helper";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BLink,
    BBadge,
    BMedia,
  },
  setup() {
    const {
      allEmployee,
      tableColumnsHome,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
    } = useUsersList();

    return {
      allEmployee,
      tableColumnsHome,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,
      mappingStatusColor,
      constructUrlImage,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
