<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col xl="12" md="12">
        <home-statistics :data="statistics" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="8">
        <home-employee-table />
      </b-col>
      <!--/ Company Table Card -->

      <!-- Developer Meetup Card -->
      <b-col lg="4" md="6">
        <home-detail />
      </b-col>
      <!--/ Developer Meetup Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import { onMounted, ref } from "@vue/composition-api";
import { companyService } from "@/services/company";
import HomeStatistics from "./HomeStatistics.vue";
import HomeEmployeeTable from "./HomeEmployeeTable.vue";
import HomeDetail from "./HomeDetail.vue";
import HomeMenu from "./HomeMenu.vue";
export default {
  components: {
    BRow,
    BCol,
    HomeMenu,
    HomeStatistics,
    HomeEmployeeTable,
    HomeDetail,
  },
  setup() {
    const statistics = ref([
      {
        icon: "TrendingUpIcon",
        color: "light-primary",
        title: "230k",
        subtitle: "จำนวนแผนก",
        customClass: "mb-2 mb-xl-0",
      },
      {
        icon: "UserIcon",
        color: "light-info",
        title: "8.549k",
        subtitle: "จำนวนพนักงาน",
        customClass: "mb-2 mb-xl-0",
      },
      {
        icon: "BoxIcon",
        color: "light-danger",
        title: "1.423k",
        subtitle: "พนักงานสถานะปกติ",
        customClass: "mb-2 mb-sm-0",
      },
      {
        icon: "DollarSignIcon",
        color: "light-success",
        title: "$9745",
        subtitle: "พนักงานเฝ้าระวัง",
        customClass: "",
      },
    ]);

    onMounted(() => {
      getCompanyStatic();
    });

    const getCompanyStatic = async () => {
      try {
        const userID = localStorage.getItem("userid");
        const result = await companyService.getStatic(userID);

        if (result.status) {
          statistics.value[0].title = result.data.totalDepartment;
          statistics.value[1].title = result.data.totalEmployee;
          statistics.value[2].title = result.data.totalNormalEmployee;
          statistics.value[3].title = result.data.totalAbnormalEmployee;
        }
      } catch (e) {
        console.log("ERROR => ", e);
      }
    };

    return {
      statistics,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
